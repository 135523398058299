import React, {useEffect, useState, useContext} from 'react';
import TextToSpeechAction from './action';
import TextToSpeechResult from './result';
import TextToSpeechInfo from './info';
import {FAQ} from '../faq';
import {UserContext} from '../../context/user-context';
import {getPackages, getUser} from '../../requests';
import Switch from '../../components/switch';
import ProductCard from '../../components/pricing/productCard';
import {closeIcon} from '../../img/svg-icons';
import pricingIcon from '../../img/payments/pricing-hint.png';

const TextToSpeechWrapper = () => {
  const [urlPath, setUrlPath] = useState('');
  const [plans, setPlans] = useState({
    ltd: null as any,
    monthly: null as any,
    annual: null as any,
  });

  //   const [plans, setPlans] = useState({
  //     "ltd": null,
  //     "monthly": [
  //         {
  //             "id": 3,
  //             "name": "STARTER_MONTH",
  //             "price": 20,
  //             "stripe_price_id": "price_1PDQScKi5i4MrUkxkcM6Zuj2",
  //             "limit": "25,000",
  //             "product_type": "stripe",
  //             "paypal_id": 7,
  //             "disabled": true
  //         },
  //         {
  //             "id": 4,
  //             "name": "PRO_MONTH",
  //             "price": 49,
  //             "stripe_price_id": "price_1PDQShKi5i4MrUkx6MJ3Wzuo",
  //             "limit": "100,000",
  //             "product_type": "stripe",
  //             "paypal_id": 6,
  //             "disabled": true
  //         }
  //     ],
  //     "annual": [
  //         {
  //             "id": 11,
  //             "name": "STARTER_ANNUAL",
  //             "price": 120,
  //             "stripe_price_id": "price_1PGlPgKi5i4MrUkxopp5qVLG",
  //             "limit": "25,000",
  //             "product_type": "stripe",
  //             "paypal_id": 12,
  //             "realPrice": 240,
  //             "realPriceMonth": 20,
  //             "disabled": false
  //         },
  //         {
  //             "id": 10,
  //             "name": "PRO_ANNUAL",
  //             "price": 294,
  //             "stripe_price_id": "price_1PGlQJKi5i4MrUkxiSgan49K",
  //             "limit": "100,000",
  //             "product_type": "stripe",
  //             "paypal_id": 13,
  //             "realPrice": 588,
  //             "realPriceMonth": 49,
  //             "disabled": false
  //         }
  //     ]
  // })
  const [annual, setAnnual] = useState(true);
  const {user, setUser} = useContext(UserContext);
  const [showUpgradeContact, setShowUpgradeContact] = useState(true);
  const [allDisabled, setAllDisabled] = useState(false);

  const setPlansData = async () => {
    const plansLocal = await getPackages();
    const userPlan = user?.package_data?.name;
    userPlan === 'PRO_ANNUAL' ? setAllDisabled(true) : setAllDisabled(false);
    if (plansLocal && Array.isArray(plansLocal)) {
      const monthlyPlans = plansLocal
        .filter((item: any) => item.name !== 'free' && item.name?.includes('MONTH') && item.product_type === 'stripe')
        .map((item: any) => {
          return {
            ...item,
            paypal_id: plansLocal.find((sub: any) => sub.product_type === 'paypal' && sub.name === item.name)?.id,
            disabled: (userPlan?.includes('STARTER') && item.name.includes('STARTER')) || userPlan?.includes('PRO'),
            limit: item.name.includes('STARTER') ? '25,000' : '3',
          };
        })
        .sort((a: any, b: any) => {
          if (a.price < b.price) {
            return -1;
          } else {
            return 1;
          }
        });
      const annualPlans = plansLocal
        .filter(
          (item: any) =>
            item.name !== 'free' &&
            item.name?.includes('ANNUAL') &&
            item.product_type === 'stripe' &&
            !item.name?.includes('OLD'),
        )
        .map((item: any) => {
          return {
            ...item,
            paypal_id: plansLocal.find((sub: any) => sub.product_type === 'paypal' && sub.name === item.name)?.id,
            realPrice: item.name === 'PRO_ANNUAL' ? 588 : 240,
            realPriceMonth: item.name === 'PRO_ANNUAL' ? 49 : 20,
            disabled: (userPlan === 'STARTER_ANNUAL' && item.name === 'STARTER_ANNUAL') || userPlan === 'PRO_ANNUAL',
            limit: item.name.includes('STARTER') ? '25,000' : '36',
          };
        })
        .sort((a: any, b: any) => {
          if (a.price < b.price) {
            return -1;
          } else {
            return 1;
          }
        });
      plansLocal &&
        setPlans({
          ltd: null as any,
          monthly: monthlyPlans,
          annual: annualPlans,
        });
    }
  };

  useEffect(() => {
    setPlansData();
  }, [user]);

  const refetchUser = async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  };

  const handleCloseModal = () => setShowUpgradeContact(false);

  useEffect(() => {
    setTimeout(() => {
      const path = global?.window?.location?.href;
      if (path && path?.includes('#pricing')) {
        document?.body?.scrollHeight && global?.window?.scrollTo(0, document?.body?.scrollHeight);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const path = global?.window?.location?.pathname;
    const cleanPath = path && path.replace('/', '').replace('/', '');
    const cleanPathVal = cleanPath && parseInt(cleanPath);
    const url = localStorage.getItem('text-reader-result');
    if (cleanPathVal && typeof cleanPathVal === 'number' && !isNaN(cleanPathVal) && url) {
      setUrlPath(url);
    } else {
      setUrlPath('');
    }
  }, []);

  return (
    <>
      {urlPath ? <TextToSpeechResult urlPath={urlPath} /> : <TextToSpeechAction />}
      <TextToSpeechInfo />
      <div className="my-5">
        <div>
          <h2 className="mb-2 text-center pricing-section-title" id="pricing">
            Text Reader Pricing Plans
          </h2>

          {/* <p className="text-center section-text lg">
            Take advantage of our <span style={{color: '#7C3AED'}}> SPECIAL LAUNCH OFFER </span> and save{' '}
            <span style={{color: '#7C3AED'}}>50% OFF</span> our plans for a limited time only.
          </p> */}
          {/* <Counter date={getPromoDate()} title="DEAL ENDS IN..." /> */}
        </div>

        <div className="pricing-wrapper position-relative">
          {/* <div className="d-inline-block position-relative wrap-el w-100">
            <Switch
              text1="Monthly"
              text2="Annually"
              active={!annual}
              onChange={(val: string) => setAnnual(val === 'Annually')}
            />
          </div> */}
          {allDisabled && showUpgradeContact && (
            <div className="text-center all-disabled">
              <span onClick={handleCloseModal}>{closeIcon}</span>
              <img src={pricingIcon} alt="icon" />
              <div>Need to upgrade your plan? </div>
              <div className="support">
                Please contact{' '}
                <a
                  href="mailto:support@textreader.ai?subject=Text Reader"
                  aria-label={'mail'}
                  target="_blank"
                  rel="noopener"
                >
                  support@textreader.ai
                </a>
                {'.'}
              </div>
            </div>
          )}
          <div className="d-block d-lg-flex products-wrapper justify-content-center">
            {(plans?.monthly && plans?.annual && [...plans.monthly, ...plans.annual])?.map(
              (item: any, index: number) => (
                <ProductCard
                  id={item.id}
                  name={item.name}
                  paypal_id={item.ltd ? item.ltd.paypal_id : item.paypal_id}
                  color={item.color}
                  stack={true}
                  count={1}
                  key={item.name}
                  price={item.price}
                  ltdPrice={item.ltd?.amount}
                  popular={false}
                  expariationDate={true}
                  // pendingPayment={pendingPayment}
                  user={!!(user && user.id)}
                  verified={true}
                  ltdUser={false}
                  validUser={true}
                  disabled={item.ltd ? item.ltd.disabled : item.disabled}
                  limit={item.limit}
                  refetchUser={refetchUser}
                  realPriceMonth={item.realPriceMonth}
                  realPriceAnnual={item.realPrice}
                  annual={annual}
                />
              ),
            )}
          </div>
          {/* <div className="text-center mt-4" style={{color: '#697b93', fontSize: '14px', fontWeight: 500}}>
            All purchases are covered by our 30 day no questions asked refund policy.
          </div> */}
        </div>
      </div>
      <div className="px-4">
        <FAQ />
      </div>
    </>
  );
};

export default TextToSpeechWrapper;
