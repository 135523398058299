import React, {FC, useState} from 'react';
import {CardFormComponentProps} from './types';
import {formatCreditCardNumber, formatExpirationDate, formatCVC} from './helpers';
import {Input} from '../../form/input';
import Visa from '../../../img/payments/Visa.png';
import AmericanExpress from '../../../img/payments/AmericanExpress.png';
import MasterCard from '../../../img/payments/MasterCard.png';
import UnionPay from '../../../img/payments/UnionPay.png';
import Discover from '../../../img/payments/Discover.png';
import DinersClub from '../../../img/payments/DinersClub.png';
import JCB from '../../../img/payments/JCB.png';
import { eyeIcon } from '../../../img/svg-icons';

const CreditCardFormComponent: FC<CardFormComponentProps> = ({
  cardData,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  disabled,
  fieldError,
}) => {
  const [showCVV, setShowCVV] = useState(false);
  return (
    <>
      <div className="profile-payment-inputs" style={{opacity: disabled ? '0.5' : 1}}>
        <div className='pb-3'>
          <Input
            label={'Cardholder’s Name'}
            name="name"
            type='text'
            placeholder="Name on the card"
            value={cardData.name}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
            disabled={disabled}
            error={fieldError && !cardData.name}
            className='input'
          />
        </div>
        <div className='pb-3'>
          <Input
            label={'Credit/Debit Card Number'}
            name="number"
            type='text'
            placeholder="Card number"
            value={formatCreditCardNumber(cardData.number)}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
            disabled={disabled}
            error={fieldError && !cardData.number}
            className='input'
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-6 pb-4">
            <Input
              label={'Expiration'}
              name="expiry"
              placeholder="MM/YY"
              value={formatExpirationDate(cardData.expiry)}
              onChange={onFieldChange}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              required
              disabled={disabled}
              error={fieldError && !cardData.expiry}
              type="text"
              className='input'
            />
          </div>
          <div className="col-12 col-lg-6 col-sm-6 position-relative pb-4">
            <Input
              type={showCVV ? 'number' : 'password'}
              name="cvc"
              label={'Security code'}
              placeholder="CVV/CVC"
              className={`input ${fieldError && !cardData.cvc ? 'error-border' : ''}`}
              value={formatCVC(cardData.cvc, cardData.number)}
              onChange={onFieldChange}
              onFocus={onFieldFocus}
              onBlur={onFieldBlur}
              required
              disabled={disabled}
            />
            <div
              className={`eye-icon position-absolute ${showCVV ? ' active' : ''}`}
              onClick={e => !disabled && setShowCVV(!showCVV)}
            >
              {eyeIcon}
            </div>
          </div>
        </div>
      </div>
      <div className="payment-icons text-center d-flex align-items-center justify-content-center">
        <img src={Visa} alt="Visa" className="m-1" loading="eager" />
        <img src={MasterCard} alt="Master Card" className="m-1" loading="eager" />
        <img src={AmericanExpress} alt="American Express" className="m-1" loading="eager" />
        <img src={Discover} alt="Discover" className="m-1" loading="eager" />
        <img src={JCB} alt="JCB" className="m-1" loading="eager" />
        <img src={DinersClub} alt="Diners Club" className="m-1" loading="eager" />
        <img src={UnionPay} alt="Union Pay" className="m-1" loading="eager" />
      </div>
    </>
  );
};

export default CreditCardFormComponent;
