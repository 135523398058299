import React, {FC} from 'react';
import {downloadIcon} from '../../img/svg-icons';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import 'react-h5-audio-player/src/styles.scss';
import './styles.scss';
import { handleDownload } from '../../helpers';
interface IAudioProps {
  autoplay?: boolean;
  src: string;
  onPlay?: (e: any) => void;
  onPause?: (e: any) => void;
  onListen?: (e: any) => void;
  download?: boolean;
  showDownloadProgress?: boolean;
  onPlayError?: (e: any) => void;
}
const CusttomAudioPlayer: FC<IAudioProps> = ({
  autoplay,
  src,
  onPause,
  onPlay,
  onListen,
  download,
  showDownloadProgress,
  onPlayError,
}) => {

  return (
    <div className="d-flex p-0">
      <div className={`play-audio w-100 mr-2`}>
        <AudioPlayer
          autoPlay={autoplay}
          src={src}
          onPlay={onPlay}
          onPause={onPause}
          onListen={onListen}
          showDownloadProgress={showDownloadProgress}
          onPlayError={onPlayError}
        />
      </div>
      {download && (
        <div onClick={() => handleDownload(src)} className={`ml-2 d-flex my-auto cursor-pointer position-relative download-mp3 ${src ? '' : 'disabled'}`}>
          <div style={{cursor: 'poniter'}}>{downloadIcon}</div>
          {/* <div className="position-absolute download-mp3-hover">Download MP3</div> */}
        </div>
      )}
    </div>
  );
};

export default CusttomAudioPlayer;
