import React from 'react';
import Layout from '../components/layout';
import TextToSpeechWrapper from '../components/text-area';

const IndexPage = () => {
  return (
    <Layout
      bg
      title={'Text Reader - Free text to speech generator with realistic AI voices'}
      metaName={'description'}
      metaContent={
        'Generate lifelike audio in seconds, ideal for podcasts, video voice-overs, personal greetings, IVR phone systems and more.'
      }
    >
      <div style={{maxWidth: '1000px', margin: 'auto'}}>
        <h1 className="text-center" style={{color: '#D6D6D6', fontSize: '40px', fontWeight: 700, lineHeight: 'normal'}}>
          Text Reader - Text to speech generator with realistic AI voices
        </h1>
        <h2
          className="text-center mb-4 px-2"
          style={{color: '#697B93', fontSize: '24px', fontWeight: 500, lineHeight: 'normal'}}
        >
          Generate lifelike audio in seconds, ideal for podcasts, video voice-overs, personal greetings, IVR phone
          systems and more.
        </h2>
      </div>

      <TextToSpeechWrapper />
    </Layout>
  );
};

export default IndexPage;
