import React, {FC, useEffect, useState} from 'react';
import CusttomAudioPlayer from '../audio-player';
import Button from '../button';
import {navigate} from 'gatsby';
import './styles.scss';
import {arrowDownIcon} from '../../img/svg-icons';

const TextToSpeechAction: FC<{urlPath: any}> = ({urlPath}) => {
  const [timeStamp, setTimeStamp] = useState(0);
  const [play, setPlay] = useState(false);
  const [textArray, setTextArray] = useState(['', '']);
  const [show, setShow] = useState(false);

  const [text, setText] = useState('');

  const handlePause = () => setPlay(false);
  const handlePlay = () => {
    setPlay(true);
  };
  const handleListen = (e: any) => {
    setTimeStamp(e.timeStamp);
  };
  const getPercent = (str: string, str1: string) => {
    const start = +str.slice(0, str.indexOf(':')) * 60 + +str.slice(str.indexOf(':') + 1);
    const end = +str1.slice(0, str1.indexOf(':')) * 60 + +str1.slice(str1.indexOf(':') + 1);
    return Math.ceil((100 * start) / end);
  };

  useEffect(() => {
    const time = document.getElementById('rhap_current-time');
    const fullTime = document.querySelector('.rhap_total-time');
    if (fullTime && time) {
      const transText = text || urlPath;
      const percent = getPercent(time.innerText, fullTime.innerHTML);
      const a = transText && transText.slice(0, Math.ceil((transText.length * percent) / 93.5));
      transText && a && setTextArray([a, transText.replace(a, '')]);
      percent === 100 && transText && setTextArray([transText, '']);
      !percent && transText && setTextArray(['', transText]);
    }
  }, [urlPath, timeStamp, play]);

  return (
    <div style={{maxWidth: '1200px'}} className="m-auto px-2">
      <div className="text-to-speech-action sm">
        <div className="form-result row">
          <CusttomAudioPlayer
            download
            src={urlPath}
            onPlay={handlePlay}
            onPause={handlePause}
            onListen={handleListen}
            showDownloadProgress={!urlPath}
            // onPlayError={handleClick}
            autoplay={!!urlPath}
          />
          <div className={`show-raw-url mt-2 ${show ? 'open' : ''}`} onClick={() => setShow(!show)}>
            {show ? 'Hide' : 'Show'} the link {arrowDownIcon}
          </div>
          {show ? <div className="mt-3">{urlPath}</div> : ''}
          <button className="gradient-border-button generate-new sm" onClick={() => navigate('/')}>
            <span className="button-content">Generate new</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default TextToSpeechAction;
