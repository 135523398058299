import React, {FC, memo} from 'react';
import {Modal as CustomModal} from '../../components/modal';
import googleIcon from '../../img/google-icon.svg';
import { login } from '../../requests';

const SignInToContinue:FC<any> = ({handleClose}) => {
  const handleClick = async () => {
    const url = await login('en');
    if (url) window.location.href = url;
  };

  return (
    <CustomModal modalToggle={handleClose} isOpen={true} dark>
      <div className="text-center">
        <div className='mb-4' style={{color: '#fff', fontSize: '20px'}}>Sign in to continue </div>
        <button className="button sign-in mx-auto modal-btn" onClick={handleClick}>
          <img src={googleIcon} className="google-icon" alt="Google" />
          Sign in with Google
        </button>
      </div>
    </CustomModal>
  );
};

export default memo(SignInToContinue);
