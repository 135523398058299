import React from 'react';
import picture from '../../img/pic-1.png';
import picture2 from '../../img/pic-2.png';
import picture3 from '../../img/pic-3.png';
import picture4 from '../../img/pic-4.png';
import picture5 from '../../img/pic-5.png';
import picture6 from '../../img/pic-6.png';
import picture7 from '../../img/pic-7.png';
import picture8 from '../../img/pic-8.png';
import {textToSpeechCountries} from '../../constants';
import './styles.scss';

const Lang = ({country, lang}: any) => (
  <div>
    <div className="text-center">{country}</div>
    <div className="text-center">{lang}</div>
  </div>
);
const TextToSpeechInfo = () => (
  <div className="row text-to-speech-info mx-0 mt-5 px-4">
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 p-2 m-auto text-center" style={{maxWidth: '1200px', margin: 'auto'}}>
        <h4 className="mb-4">Turn written text into compelling, lifelike speech in seconds. </h4>
        <div className="mb-4 info-text">
          Unlock the power of voice with Text Reader, a user-friendly tool designed to transform written words into
          realistic audio effortlessly. Say goodbye to the monotony of reading - with Text Reader, you can breathe life
          into your content at no cost.
        </div>
        <div className="mb-2 info-text">
          Featuring high fidelity TTS WaveNet voices, our text to speech tool reads text aloud and enables you to
          download voice audio in MP3 format.
        </div>
      </div>
    </div>
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 d-none d-md-flex text-center justify-content-center my-auto">
        <img src={picture} alt="text to speech" />
      </div>
      <div className="col-12 col-md-6 mb-5">
        <h4 className="mb-4">Automate time consuming voice recording tasks with Text Reader</h4>
        <div className="mb-2 info-text">
          Save on production costs by converting any text content to realistic audio in seconds. Simply input your text,
          choose the voice actor, and let Text Reader do the rest.
        </div>
        <div className="mb-4 info-text">
          With Text Reader's simple interface, crafting engaging and natural-sounding audio has never been easier.
        </div>
      </div>
      <div className="col-12 col-md-6 d-md-none text-center justify-content-center my-auto">
        <img src={picture} alt="text to speech" />
      </div>
    </div>
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 m-auto">
        <h4 className="mb-4">AI Text To Speech For Personal Use</h4>
        <div className="mb-2 info-text">AI text to speech is a game-changer for personal productivity.</div>
        <div style={{color: '#fff'}} className="info-text">
          Easily Convert Written Notes into Audio
        </div>
        <div className="mb-2 info-text">
          Imagine capturing the essence of your thoughts or jotting down notes in text form and then, with a click of a
          button, listening to them as if they were being read aloud by a professional narrator.
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex text-center justify-content-center my-auto">
        <img src={picture2} alt="text to speech" />
      </div>
    </div>
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 d-none d-md-flex text-center justify-content-center my-auto">
        <img src={picture3} alt="text to speech" />
      </div>
      <div className="col-12 col-md-6 mb-5 m-auto">
        <h4 className="mb-4">Convert Blogs, Articles, or Any Written Content into Audio</h4>
        <div className="mb-2 info-text">
          Consume longer form content on-the-go, be it while driving, exercising, or during a commute.
        </div>
        <div className="mb-4 info-text">
          Text Reader also enables individuals with visual impairments or reading difficulties to access information
          with ease.
        </div>
      </div>
      <div className="col-12 col-md-6 d-md-none text-center justify-content-center my-auto">
        <img src={picture3} alt="text to speech" />
      </div>
    </div>
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 mb-5 p-2 m-auto">
        <h4 className="mb-4">Create personal audio greetings in seconds </h4>
        <div className="mb-2 info-text">
          With Text Reader, you can generate personal greetings that convey warmth and personal sentiment more
          effectively than text.
        </div>

        <div className="mb-4 info-text">
          Whether you’re sending out heartfelt birthday wishes, holiday greetings, or making special announcements,
          these personalized audio messages can leave a lasting impression on the recipient and mark moments with a
          genuine, human touch.
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex text-center justify-content-center my-auto">
        <img src={picture4} alt="text to speech" />
      </div>
    </div>
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 d-none d-md-flex text-center justify-content-center my-auto">
        <img src={picture5} alt="text to speech" />
      </div>
      <div className="col-12 col-md-6 mb-5 m-auto">
        <h4 className="mb-4">AI Voice Generator For Commercial Use</h4>
        <div className="mb-2 info-text">
          Unlike hiring voice artists and renting recording studios, which can be quite costly and time-consuming, Text
          Reader offers a cost-effective and efficient alternative for commercial audio.
        </div>
      </div>
      <div className="col-12 col-md-6 d-md-none text-center mb-5">
        <img src={picture5} alt="text to speech" />
      </div>
    </div>

    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 mb-5 p-2 m-auto">
        <h4 className="mb-4">Engaging Prospective Clients</h4>
        <div className="mb-2 info-text">
          Captivating voiceovers can make a world of difference. Text Reader's text to speech technology allows
          businesses to enhance promotional videos with professional voiceovers, showcasing products and services in an
          engaging manner.
        </div>
        <div className="mb-4 info-text">
          The ability to effortlessly convert text into speech in multiple languages means companies can tailor their
          messaging to reach a global audience, opening up new markets and opportunities.
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex text-center justify-content-center my-auto">
        <img src={picture6} alt="text to speech" />
      </div>
    </div>
    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 d-none d-md-flex text-center justify-content-center my-auto">
        <img src={picture7} alt="text to speech" />
      </div>
      <div className="col-12 col-md-6 mb-5 m-auto">
        <h4 className="mb-4">Augment Customer Service</h4>
        <div className="mb-2 info-text">
          If you’re using a Voice Response (IVR) system, Text Reader can provide instant recordings for consistent and
          personalized interactions without the need for live agents.
        </div>
        <div className="mb-4 info-text">
          IVR systems ensure customers receive timely and efficient service while reducing wait times and operational
          costs.
        </div>
      </div>
      <div className="col-12 col-md-6 d-md-none text-center mb-5">
        <img src={picture7} alt="text to speech" />
      </div>
    </div>

    <div className="row p-0 mx-0 my-4">
      <div className="col-12 col-md-6 mb-5 p-2 m-auto">
        <h4 className="mb-4">Educational Content: Making Learning Accessible  </h4>
        <div className="mb-2 info-text">
          Text Reader's AI voice generator is ideal in the educational sphere by aiding learning and improving
          accessibility. It serves as a beneficial tool for students, especially those facing challenges like dyslexia
          and other learning differences.
        </div>

        <div className="mb-4 info-text">
          Text Reader can convert educational texts into audio, thus aiding comprehension and memory retention. It also
          improves pronunciation and listening skills in different languages, providing a valuable resource for language
          learners. Moreover, educators can create engaging, inclusive content that caters to diverse learning needs,
          ensuring no student is left behind.
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex text-center justify-content-center my-auto">
        <img src={picture8} alt="text to speech" />
      </div>
    </div>

    <h5 className="mb-2 text-center" style={{color: '#D6D6D6', fontSize: '30px', fontWeight: 600}}>
      Multilingual natural voices for a global audience
    </h5>
    <div
      className="text-center mb-4 info-text"
      style={{maxWidth: '680px', margin: 'auto', fontSize: '18px', color: '#8793ab'}}
    >
      Create high-quality natural-sounding male or female voices for up to 40 different languages including:
    </div>
    <div className="col-12 mb-2 d-flex countries">
      {textToSpeechCountries.map(({country, lang}, index) => (
        <div className="language d-flex justify-content-center align-items-center m-2 p-2" key={index}>
          <Lang country={country} lang={lang} />
        </div>
      ))}
    </div>
  </div>
);
export default TextToSpeechInfo;
