import React, {FC, useEffect, useState} from 'react';
import './styles.scss';

const CustomProgress:FC<any> = ({percent, text, noMessage, time, className, func}) => {
  const [count, setCount] = useState(0);
  let interval: any = null;
  useEffect(() => {
    interval = setInterval(function() {
      setCount(count => {
        if (count + 2 >= 96) {
          clearInterval(interval);
        }
        return count + 2;
      });
    }, time || 100);

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (count === 96) func && func();
  }, [count]);
  return (
    <div className={`div-section ${className ? className : ''}`}>
      <div className={`final__animation-bar-2 ${noMessage ? '' : 'show-tooltip'}`}>
        <span data-label={text || percent || count + '%'} style={{width: percent || count + '%'}}></span>
      </div>
    </div>
  );
};

export default CustomProgress;
