import React from 'react';
import './styles.scss';
import {FAQItem} from './faq-item';

const faqArr = [
  {
    q: 'How Do I Convert Text to Voice?',
    a: [
      'Converting text to voice with Text Reader is a user-friendly process that requires minimal effort. Here are the simple steps to follow:',
      [
        'Paste or type the text you wish to convert into the designated text box.',
        'Select the desired language and voice from the available options that align with your project needs.',
        'Click the “Go” button to initiate the process.',
      ],
      `The text will be processed, and in moments, you'll hear the natural-sounding speech output. If satisfied, you can download the audio file for your use.`,
      'With these straightforward steps, you can transform articles, books, and scripts into high-quality audio content with just one click.',
    ],
  },
  {
    q: 'What Sets Text Reader Voices Apart?',
    a: [
      'Text Reader stands out for several reasons:',
      'Advanced AI Algorithms: Text Reader employs sophisticated artificial intelligence algorithms and linguistic rules that meticulously analyze text for a precise understanding, ensuring high accuracy in voice output powered by Google AI.',
      'Natural-Sounding Speech: The technology simulates realistic human speech patterns, capturing nuances such as tone, emphasis, and rhythm, making the listener experience more engaging.',
      'Multilingual Capabilities: It offers an extensive range of languages and accents to cater to a varied and international audience.',
      `Continuous Improvement: As AI and machine learning evolve, so too does Text Reader's capacity to deliver even more refined and life-like voices.`,
    ],
  },
  {
    q: 'Why Choose AI Voiceovers Over Human Narration?',
    a: [
      'Opting for AI voiceovers instead of human narration comes with several compelling advantages:',
      'Cost-Effective: Reduces production expenses significantly by eliminating the need for professional voice artists.',
      'Time Efficient: With the ability to convert text rapidly, turnaround times are quicker than coordinating recording sessions with humans.',
      'Versatility and Convenience: Provides the ability to easily modify or update voiceovers without the need to rehire talent.',
      `Consistency: Offers uniform vocal quality that doesn't vary with each reading, ensuring a consistent brand image or user experience.`,
    ],
  },
  {
    q: 'Can I Use AI Voices for Commercial Projects?',
    a: [
      `Absolutely! Text Reader is an excellent tool for creating engaging voice content for a variety of commercial projects. Here's a list of examples where AI voices can be used:`,
      [
        'Voiceovers for videos and animations',
        'Audiobook production',
        'Podcast narratives',
        'Gaming character voices',
        'Educational tutorials and courses',
        'Marketing and promotional materials',
      ],
    ],
  },
  {
    q: 'How Fast Can Text Reader Convert Text to Speech?',
    a: [
      'Our online text to speech converter takes seconds to generate human-like speech in your desired language. Once the audio file is ready, it is available to download in MP3 with a single click.',
    ],
  },
];
export const FAQ = () => (
  <div className="faq-section">
    <h2 className="faq-section-title">Text to Speech FAQ</h2>
    <h3 className="faq-section-subtitle">
      Text-to-speech (TTS) technology is revolutionizing the way we consume written content by providing efficient tools
      to convert text into spoken words with ease. 
    </h3>
    <p className="faq-section-desc">
      Many people have questions about how text-to-speech works, the advantages AI voices have over traditional
      voiceovers, and the potential uses for TTS in various projects. Below, we explore some of the most frequently
      asked questions to provide you with clear insights into the groundbreaking world of Text Reader.
    </p>
    {faqArr.map((item, index) => (
      <div key={index}>
        <FAQItem question={item.q} answer={item.a} />
      </div>
    ))}
  </div>
);
