import React, {memo, FC} from 'react';
import Button from '../../button';
import {thankYou} from '../../../img/svg-icons'
import './styles.scss';

const PaymentSuccess: FC<{onClick: any; paypal?: boolean}> = ({
  onClick,
  paypal,
}) => {
  return (
    <>
      <div style={{maxWidth: '500px', margin: 'auto'}}>
        <div className="mx-auto" style={{width: 'fit-content', marginTop: '4vh'}}>
          <div className="d-flex align-items-center justify-content-center">{thankYou}</div>
        </div>
        <h3 className="text-center mt-5" style={{fontWeight: 700, color: '#00042a', fontSize: '24px'}}>
          Thank you
        </h3>
        <h3 className="text-center mt-2" style={{fontSize: '18px', color: '#516077', fontWeight: 400}}>
          {paypal
            ? 'Your payment is being processed (can take a few minutes to show in your account).'
            : 'Payment successfully completed.'}
        </h3>
        <div className="d-flex align-items-center justify-content-center mt-4">
          <Button onClick={onClick} disabled={false} aboutVideo>
            Close
          </Button>
        </div>
      </div>
    </>
  );
};

export default memo(PaymentSuccess);
