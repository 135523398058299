import React from 'react';
import './styles.scss';

export const FAQItem = ({question, answer}: any) => (
  <div className="faq-item mb-4">
    <div className="faq-q mb-2">{question}</div>
    {answer.map((item: any, ind: number) =>
      Array.isArray(item) ? (
        item.map((subItem, index) => (
          <p className="faq-a-subitem" key={index}>
            {subItem}
          </p>
        ))
      ) : (
        <p className="faq-a" key={ind}>
          {item}
        </p>
      ),
    )}
  </div>
);
