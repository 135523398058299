import React, {FC, FormEvent} from 'react';
import './styles.scss';

interface TextAreaProps {
  value: string;
  onChange: (e: FormEvent) => void;
  placeholder?: string;
  name?: string;
  rows?: number;
  className?: string;
  onBlur?: Function;
  onFocus?: Function;
  disableResize?: boolean;
  maxLength?: number;
  isShowLength?: boolean;
  disabled?: boolean;
  handleButtonClick?: any;
  fileInputRef?: any;
  handleFileChange?: any;
}

export const TextArea: FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  name,
  rows,
  className,
  onBlur,
  onFocus,
  disableResize,
  maxLength,
  isShowLength,
  disabled,
  handleButtonClick,
  fileInputRef,
  handleFileChange,
}) => (
  <div className="position-relative h-100">
    <textarea
      className={`textarea ${className ? className : ''} ${disableResize ? 'disable-resize' : ''}`}
      name={name}
      placeholder={placeholder}
      value={value}
      rows={rows || 6}
      onChange={onChange}
      onBlur={() => onBlur && onBlur()}
      onFocus={() => onFocus && onFocus()}
      maxLength={maxLength}
      disabled={disabled}
    />
    {maxLength && isShowLength && (
      <div className='d-flex'>
        <div>
          <div className='clickable-text' onClick={handleButtonClick}>Upload file (.txt)</div>
          <input accept=".txt" size={100 * 1024} type="file" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />
        </div>
        <div className="text-size">
          {value?.length || 0 + '/' + maxLength}
        </div>
      </div>
    )}
  </div>
);
