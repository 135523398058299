import React from 'react';
// import {InputProps} from '../../../types';
import '../styles.scss';

export const Input = ({
  className,
  type,
  name,
  placeholder,
  onChange,
  value,
  required,
  disabled,
  style,
  min,
  max,
  maxLength,
  onBlur,
  onFocus,
  onKeyDown,
  autocompleteOff,
}: any): JSX.Element => (
  <input
    required={required}
    type={type}
    name={name}
    style={style}
    disabled={disabled}
    onBlur={onBlur}
    onFocus={onFocus}
    onKeyDown={onKeyDown}
    placeholder={placeholder ? placeholder : ''}
    className={className ? `${className} input` : 'input'}
    onChange={(event: React.SyntheticEvent) => onChange(event)}
    value={value}
    data-testid="input"
    min={min}
    max={max}
    maxLength={maxLength}
    autoComplete={autocompleteOff ? 'off' : 'on'}
  />
);
