import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../context/user-context';
import {closeIcon, paypalBtnIcon, cardIcon, paypalIcon} from '../../../img/svg-icons';
import CreditCardForm from '../creditCardForm';
import PaymentLoader from '../paymentLoader';
import PaymentSuccess from '../paymentSuccess';
import {createCard, getUser} from '../../../requests';
import RadioButton from '../../radio-button';
import './styles.scss';

export interface CardStateData {
  cvc: string;
  expiry: string;
  focus: string;
  name: string;
  number: string;
}

const Payment: FC<any> = ({
  id,
  paypal_id,
  count,
  setIsOpenModal,
  showPaypalMessage,
  setShowPaypalMessage,
  price,
  name,
  refetchUser,
  annualDiscount,
  // ltd,
}) => {
  //-----------------Context-------------------
  const {user, setUser} = useContext(UserContext);
  //------------------States-------------------
  let secondWindow: any;
  let int: any;
  const [el, setEl] = useState(null as any);
  const [stripe, setStripe] = useState<any>(null);
  const [cardId, setCardId] = useState('');
  const [customLoading, setCustumLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(count);
  const [paypal, setPaypal] = useState(false);

  const [editCardInfo, setEditCardInfo] = useState(2);
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [paypalToken, setPaypalToken] = useState('');
  const [cardInfo, setCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [defaultCardInfo, setDefaultCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [paidWithPaypal, setPaidWithPaypal] = useState(false);

  const getUserInfo = useCallback(async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  }, [getUser]);

  //-------------------useEffects--------------------------
  useEffect(() => {
    if (success) getUserInfo();
  }, [success]);

  useEffect(() => {
    if (user?.package_data?.product_type === 'paypal' && name && name !== 'free') {
      setPaypal(true);
    } else {
      setPaypal(false);
    }
  }, [user?.package_data?.product_type]);

  useEffect(() => {
    //@ts-ignore
    window['Stripe'].setPublishableKey(
      window.location.hostname.includes('textreader.ai')
        ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
        : 'pk_live_8KwSrH69LnrJea7rrCH7Phn1',
    );
    setStripe(
      //@ts-ignore
      window['Stripe'](
        window.location.hostname.includes('textreader.ai')
          ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
          : 'pk_live_8KwSrH69LnrJea7rrCH7Phn1',
      ),
    );
  }, []);

  const addCard = useCallback(() => {
    const expiry = cardInfo.expiry.split('/');
    try {
      //@ts-ignore
      window.Stripe.card.createToken(
        {
          number: cardInfo.number,
          exp_month: +expiry[0],
          exp_year: +expiry[1].slice(0, 2),
          cvc: cardInfo.cvc,
        },
        async (status: any, response: any) => {
          if (status === 200) {
            if (response && response.id) {
              const resp = await createCard(response.id, id, 'stripe');
              if (resp && resp.client_secret) {
                const {paymentIntent} = await stripe.confirmCardPayment(resp.client_secret, {
                  payment_method: resp.card_id,
                });
                if (paymentIntent && paymentIntent.status === 'succeeded') {
                  setSuccess(true);
                  analytics();
                } else setErr('Payment failed.');
                setCustumLoading(false);
                return;
              }
              if (resp) {
                if (resp.error) {
                  setErr('Payment failed.');
                } else {
                  setSuccess(true);
                  analytics();
                }
              }
            } else setErr('Invalid parameters.');
          } else setErr('Invalid parameters.');
          setCustumLoading(false);
        },
      );
    } catch (e) {
      setErr('Invalid card.');
    }
  }, [cardInfo, createCard, id]);

  const handlePaymentFormSubmit = useCallback(async () => {
    setErr('');
    const obj = {...cardInfo, focus: 'name'};

    if (Object.values(obj).filter(item => !item).length > 0) {
      setFieldError(true);
      return;
    }
    setFieldError(false);
    setCustumLoading(true);
    addCard();
  }, [cardInfo, addCard]);

  //functions
  const analytics = () => {
    if (window.location.href.includes('textreader.ai')) {
      //@ts-ignore
      console.log(window.dataLayer);
      //@ts-ignore
      window.dataLayer.push({
        event: 'purchase-button-click',
        purchaseOrderId: new Date().toISOString(),
        purchaseConversionValue: `${price || 0}`,
      });
      //@ts-ignore
      window.dataLayer.push({
        event: 'eec.purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: `${name}_${new Date().toISOString()}`,
              revenue: `${price || 0}`,
            },
            products: [
              {
                item_id: `${name}_${new Date().toISOString()}`,
                item_name: name,
                price: `${price || 0}`,
                quantity: count || 1,
              },
            ],
          },
        },
      });
    }
  };

  const handlePaypalPaymentCreate = async () => {
    const y = (window.outerHeight - 470) / 2;
    const x = (window.outerWidth - 400) / 2;
    secondWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
    const resp = await createCard('', paypal_id, 'paypal');

    if (resp && resp.approve_url) {
      secondWindow && (secondWindow.location = resp.approve_url);
      setEl(secondWindow);
      resp.approve_url && setPaypalToken(resp.approve_url.slice(resp.approve_url.indexOf('token=') + 6));
      setShowPaypalMessage && setShowPaypalMessage(true);
      int = setInterval(() => {
        if (!secondWindow || secondWindow.closed) {
          clearInterval(int);
          setShowPaypalMessage && setShowPaypalMessage(false);
          setEl(null);
        }
      });
    } else {
      secondWindow && secondWindow.close();
    }
  };

  const handleShowSecondWindow = () => el && el.focus();
  const handleCloseSecondWindow = () => el && el.close();

  useEffect(() => {
    if (!showPaypalMessage && localStorage.getItem('payment_success')) {
      setSuccess(true);
      localStorage.removeItem('payment_success');
      analytics();
    }
  }, [showPaypalMessage]);

  const eventStop = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleCloseModal = (e: any) => {
    success && refetchUser();
    setIsOpenModal();
    eventStop(e);
  };

  const handlePaypalPaymentFormSubmit = () => {
    setErr('');
    handlePaypalPaymentCreate();
  };

  return (
    <>
      {!showPaypalMessage ? (
        <div className="payment">
          {!customLoading ? (
            !success ? (
              <>
                <div className={`payment_toolbar d-flex justify-content-between align-items-center `}>
                  <div className="ml-3">
                    <div className="d-flex align-items-center automations-credits">
                      <div className="package">{name}</div>
                    </div>
                    <div className="purchase">
                      ${price} {annualDiscount ? 'billed annually' : 'billed upon purchase'}
                    </div>
                  </div>
                </div>
                {/* {
                  <PaymentChooseCard
                    onChange={setEditCardInfo}
                    editCardInfo={editCardInfo}
                    defaultCard={!!(defaultCardInfo && defaultCardInfo.name)}
                    paypal={!(user?.package_data?.product_type === 'stripe' && name && name !== 'free')}
                    stripe={!(user?.package_data?.product_type === 'paypal' && name && name !== 'free')}
                  />
                } */}
                <div className="payment-type-container mt-3">
                  {!(
                    user?.package_data?.product_type === 'paypal' &&
                    user?.package_data?.name &&
                    user?.package_data?.name !== 'free'
                  ) ? (
                    <div className="px-4 d-flex">
                      <RadioButton enabled={!paypal} onChange={() => setPaypal(false)} label={cardIcon} />
                    </div>
                  ) : null}
                  {!(
                    user?.package_data?.product_type === 'stripe' &&
                    user?.package_data?.name &&
                    user?.package_data?.name !== 'free'
                  ) ? (
                    <div className="px-4 d-flex">
                      <RadioButton enabled={paypal} onChange={() => setPaypal(true)} label={paypalIcon} />
                    </div>
                  ) : null}
                </div>
                {paypal ? (
                  <>
                    <div className="text-center payment-text-large" style={{maxWidth: '600px', margin: '50px auto'}}>
                      Click <strong>Pay with PayPal</strong> to log into your account and confirm your purchase. You'll
                      be redirected to this page to finish up.
                    </div>
                    <div className="mt-4">
                      <button className="btn confirm-and-pay d-flex mx-auto" onClick={handlePaypalPaymentFormSubmit}>
                        <div className="d-flex mx-auto">
                          <div className="my-auto mr-2">Pay with</div> {paypalIcon}
                        </div>
                      </button>
                    </div>
                  </>
                ) : (
                  <div style={{maxWidth: '600px', width: '100%', margin: 'auto'}}>
                    <CreditCardForm
                      cardInfo={cardInfo}
                      loading={customLoading}
                      error={err}
                      changeData={(info: any) => setCardInfo(info)}
                      fieldError={fieldError}
                    />
                    <button className="btn confirm-and-pay d-flex mx-auto" onClick={handlePaymentFormSubmit}>
                      Confirm and pay
                    </button>
                    {/* <div className="d-sm-flex align-items-center payment-text">
                    <div className="text-center text-sm-left mx-2">{poweredByStripe}</div>
                    <div className={i18n.language === 'ar' ? 'text-right label' : 'label'}>{t('upgrade-footer')}</div>
                  </div> */}
                  </div>
                )}
              </>
            ) : (
              <PaymentSuccess
                onClick={(e: any) => {
                  handleCloseModal(e);
                }}
                paypal={!!paidWithPaypal}
              />
            )
          ) : (
            <PaymentLoader />
          )}
        </div>
      ) : (
        <div className="show-paypal-info">
          <span onClick={handleCloseSecondWindow} className="close-icon">
            {closeIcon}
          </span>
          <div className="paypal-wrapper">
            <div className="pb-2">{paypalBtnIcon}</div>
            Don’t see the secure PayPal browser? We’ll help you re-launch the window to complete your purchase
            <div className="cursor-pointer pt-2" onClick={handleShowSecondWindow}>
              <strong style={{color: '#fff'}}>Click to Continue</strong>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Payment;
