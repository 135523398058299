import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {TextArea} from '../form';
import Select from '../select';
import {Modal} from '../modal';
import {ERROR_MESSAGES, freeToolsErrors, genderOptions, returnSocketLink, voiceContryOptions} from '../../constants';
import {getVoices, toolRequest, request} from '../../requests';
import {InfiniteProgressBar} from '../../components/progress-loader';
import DataList from '../dataList';
import Button from '../button';
import {navigate} from 'gatsby';
import './styles.scss';
import {closeIconWithBackground} from '../../img/svg-icons';
import SignInToContinue from '../sign-in';
import {UserContext} from '../../context/user-context';

const TextToSpeechAction: FC<{source?: string; file?: string}> = ({source, file}) => {
  //-------------------------State------------------------------
  const [socket, setSocket] = useState<any>(null);
  // const [socketId, setSocketId] = useState('');
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [textArray, setTextArray] = useState(['', '']);
  const [language, setLanguage] = useState({label: '', value: ''});
  const [gender, setGender] = useState({label: 'Select', value: ''});
  const [src, setSrc] = useState('');
  const [timeStamp, setTimeStamp] = useState(0);
  const [play, setPlay] = useState(false);
  const [allDialects, setAllDialects] = useState([]);
  const [dilects, setDilects] = useState([]);
  const [voice, setVoice] = useState({label: 'Select', value: ''});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [limit, setLimit] = useState('');
  const [limitReached, setLimitReached] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [show, setShow] = useState(false);
  const [requierd, setRequeird] = useState(false);
  const [clientId, setClientId] = useState('');
  const {user} = useContext(UserContext);

  // useEffect(() => {
  //   !file && socketStart();
  // }, []);

  // useEffect(() => {
  //   if (!socket || file) return;
  //   socket.onerror = function() {
  //     console.log('Connection Error');
  //   };

  //   socket.onopen = function() {
  //     console.log('WebSocket Client Connected');
  //   };

  //   socket.onclose = () => {
  //     socketStart();
  //   };
  //   return () => {
  //     socket.close();
  //   };
  // }, [socket]);

  // useEffect(() => {
  //   if (!socket || file) return;
  //   socket.onmessage = function(e: any) {
  //     const data = e ? JSON.parse(e.data) : '';
  //     if (data && data.clientId !== clientId) {
  //       setClientId(data.clientId);
  //       return;
  //     }
  //     if (data && data.error && freeToolsErrors.includes(data.error)) {
  //       setLimit(data.error);
  //       setShow(false);
  //       setLoading(false);
  //       setSocketId('');
  //       return;
  //     }
  //     if (data && data.socketId !== socketId) return;
  //     data._text_to_speech ? setSrc(data._text_to_speech[0].replaceAll('///', '/')) : setError('Something went wrong');
  //     setSocketId('');
  //     setShow(false);
  //     //localStorage.removeItem('textToSpeechInfo');
  //     //localStorage.removeItem('textToSpeechSocketId');
  //   };
  // }, [socketId, clientId, socket]);

  useEffect(() => {
    const time = document.getElementById('rhap_current-time');
    const fullTime = document.querySelector('.rhap_total-time');
    if (fullTime && time) {
      const transText = text || source;
      const percent = getPercent(time.innerText, fullTime.innerHTML);
      const a = transText && transText.slice(0, Math.ceil((transText.length * percent) / 93.5));
      transText && a && setTextArray([a, transText.replace(a, '')]);
      percent === 100 && transText && setTextArray([transText, '']);
      !percent && transText && setTextArray(['', transText]);
    }
  }, [src, timeStamp, play, source]);

  useEffect(() => {
    const selectOptionFrom = voiceContryOptions.find(
      (item: {label: string; value: string}) => item.label === language.label,
    );
    if (selectOptionFrom) fetchLang(selectOptionFrom.value);
    else setAllDialects([]);
  }, [language]);

  useEffect(() => {
    setIsChange(true);
  }, [gender, voice, language, text]);

  useEffect(() => {
    const arr =
      gender.value === 'NEUTRAL' ? allDialects : allDialects?.filter((item: any) => item.gender === gender.value);
    setDilects(arr);
    setVoice(arr[0] || {label: 'Select', value: ''});
  }, [gender, allDialects]);

  //-----------------------functions--------------------------
  // const socketStart = () => {
  //   //@ts-ignore
  //   const newSocket = new WebSocket(returnSocketLink());
  //   setSocket(newSocket);
  // };

  const getPercent = (str: string, str1: string) => {
    const start = +str.slice(0, str.indexOf(':')) * 60 + +str.slice(str.indexOf(':') + 1);
    const end = +str1.slice(0, str1.indexOf(':')) * 60 + +str1.slice(str1.indexOf(':') + 1);
    return Math.ceil((100 * start) / end);
  };

  const fetchLang = async (lang: string) => {
    const voices = await getVoices(lang);
    voices &&
      setAllDialects(
        voices.filter(
          (item: any) =>
            item.label !== 'en-US-Journey-F' && item.label !== 'en-US-Journey-O' && item.label !== 'en-US-Journey-D',
        ),
      );
  };

  const handleClick = async () => {
    if (!user?.id) {
      setSignIn(true);
      return;
    }
    if (show || loading) return;
    if (!language.value || !gender.value || !text || !text.trim()) {
      setRequeird(true);
      return;
    }
    setRequeird(false);
    setSrc('');
    setShow(false);
    setIsChange(false);

    try {
      setLoading(true);
      const res = await toolRequest('text-to-speech', text, {
        langCode: language.value,
        gender: gender.value,
        name: voice.value,
        clientId,
        requestSource: 'free_tools',
        host_name: 'textreader',
      });
      if (res.status === 403) {
        setLimitReached(true);
        setLoading(false);
        return;
      }
      if (res?.data?.error && freeToolsErrors.includes(res.data.error)) {
        setLimit(res.data.error);
        setShow(false);
        setLoading(false);
        return;
      }
      if (res?.data?.mp3URL) {
        setSrc(res.data.mp3URL);
        // setSocketId(res.data.socketId);
        // localStorage.setItem('textToSpeechSocketId', res.data.socketId);
        //localStorage.setItem('textToSpeechInfo', JSON.stringify({text, voice, language, gender}));
        setError('');
        setShow(false);
      } else if (res.errorData) {
        if (ERROR_MESSAGES[res.errorData.message]) {
          setLimit(ERROR_MESSAGES[res.errorData.message]);
        } else {
          if (res?.errorData?.error?.includes('limit reached')) {
            setLimitReached(true);
            setLoading(false);
            return;
          }
          setError('Something went wrong. Please try again.');
        }
      }
      setLoading(false);
    } catch (error) {
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  };
  const handleChangeModalState = () => {
    setLimitReached(false);
  };
  // const getResult = async () => {
  //   if (!socketId) return;
  //   try {
  //     //setLoading(true);
  //     const res: any = await request(`result/${socketId}`);
  //     if (
  //       res &&
  //       res.data &&
  //       res.data.result &&
  //       res.data.result.error &&
  //       freeToolsErrors.includes(res.data.result.error)
  //     ) {
  //       setLimit(res.data.result.error);
  //       setShow(false);
  //       setLoading(false);
  //       setSocketId('');
  //       return;
  //     }
  //     if (res && res.data && res.data.result && res.data.result._text_to_speech) {
  //       //localStorage.removeItem('textToSpeechSocketId');
  //       //localStorage.removeItem('textToSpeechInfo');
  //       setSrc(res.data.result._text_to_speech[0].replaceAll('///', '/'));
  //       setShow(false);
  //     }
  //     setLoading(false);
  //   } catch {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    src && localStorage.setItem('text-reader-result', src);
    src && navigate(`/${new Date().getTime()}`);
  }, [src]);

  // useEffect(() => {
  //   getResult();
  // }, [socketId]);

  const fileInputRef = useRef(null);

  // Function to trigger file input click on button click
  const handleButtonClick = () => {
    //@ts-ignore
    fileInputRef?.current?.click();
  };

  // Function to handle file selection
  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];

    if (selectedFile.size >= 100 * 1024) {
      setErrorMessage('File size exceeds the maximum limit.');
      return;
    }

    if (selectedFile?.name?.endsWith('.txt')) {
      var reader = new FileReader();
      reader.onload = function(e: any) {
        const content = reader.result;
        const strlength = content?.toString()?.length;
        if (strlength && strlength > 1000) {
          setErrorMessage(
            'The file you attempted to upload exceeds the maximum allowed character limit. Please reduce the content or select a shorter file and try again.',
          );
        } else {
          content && setText(content.toString());
        }
      };
      reader.readAsText(selectedFile);
    } else {
      setErrorMessage('Please select a .txt file.');
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];

    if (selectedFile.size >= 100 * 1024) {
      setErrorMessage('File size exceeds the maximum limit.');
      return;
    }
    if (selectedFile?.name?.endsWith('.txt')) {
      var reader = new FileReader();
      reader.onload = function(e: any) {
        const content = reader.result;
        const strlength = content?.toString()?.length;
        if (strlength && strlength > 1000) {
          setErrorMessage(
            'The file you attempted to upload exceeds the maximum allowed character limit. Please reduce the content or select a shorter file and try again.',
          );
        } else {
          content && setText(content.toString());
        }
      };
      reader.readAsText(selectedFile);
    } else {
      setErrorMessage('Please select a .txt file.');
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleChangeLanguage = (item: any) => {
    setLanguage(voiceContryOptions.find((el: any) => el.label === item) || {label: item, value: ''});
  };

  useEffect(() => {
    handleChangeLanguage('English (US)');
    setGender({
      label: 'Female',
      value: 'FEMALE',
    });
  }, []);
  //----------------------------------------------
  return (
    <div style={{maxWidth: '1200px'}} className="m-auto px-2">
      <div className="text-to-speech-action">
        <div className="form row">
          <div className="col-md-8">
            <div onDrop={handleDrop} onDragOver={handleDragOver} className="h-100">
              <TextArea
                name="text"
                value={text}
                onChange={(e: any) => setText(e.target.value)}
                rows={4}
                className={`textarea ${requierd && (!text || !text.trim()) ? 'border-empty' : ''}`}
                maxLength={1000}
                isShowLength
                disabled={loading}
                disableResize
                handleButtonClick={handleButtonClick}
                fileInputRef={fileInputRef}
                handleFileChange={handleFileChange}
                placeholder="Type text or drag-and-drop a file."
              />
            </div>
          </div>
          <div className="fields-wrapper mx-0 col-md-4 mb-2 mt-4 mt-md-0">
            <div className="">
              <label>Language</label>
              <DataList
                list={voiceContryOptions}
                value={language.label}
                placeholder={'Select'}
                //@ts-ignore
                onChange={handleChangeLanguage}
                disabled={play}
                className={`${requierd && !language.value ? 'error-border' : ''}`}
              />
            </div>
            <div className="c">
              <label>Gender</label>
              <Select
                options={genderOptions}
                onChange={setGender}
                value={gender}
                disabled={play}
                empty={requierd && !gender.value}
              />
            </div>
            <div className="">
              <label>Voices</label>
              <Select options={dilects} onChange={setVoice} value={voice} disabled={play} />
            </div>
            <div className={`mx-auto col-12 my-2 ${loading ? 'visible' : 'invisible'}`}>
              <InfiniteProgressBar />
            </div>
            {error ? (
              <div className={`alert alert-danger py-1 mb-2 mx-auto`} role="alert">
                {error}
              </div>
            ) : null}
            <button className="gradient-border-button" onClick={handleClick} disabled={show || loading}>
              <span className="button-content">Generate</span>
            </button>
          </div>
        </div>
      </div>

      {limitReached && (
        <Modal
          isOpen={limitReached}
          modalToggle={handleChangeModalState}
          title="You have reached your limit."
          onButtonClick={() => {
            setLimitReached(false);
            navigate('/#pricing');
          }}
          buttonText="Upgrade"
          className=""
          dark
          headerCentered
        >
          <p className="text-center">Upgrade your plan to continue</p>
        </Modal>
      )}
      {signIn && <SignInToContinue handleClose={() => setSignIn(false)} />}
      {errorMessage && (
        <Modal isOpen={errorMessage} modalToggle={() => setErrorMessage('')} title="" className="" dark headerCentered>
          <div className="text-center mb-5 modal-msg-icon">{closeIconWithBackground}</div>
          <p className="text-center">{errorMessage}</p>
        </Modal>
      )}
    </div>
  );
};
export default TextToSpeechAction;
